<template>
  <div>
    <a class="btn-scroll--to_top btn-border" @click="scrollTop" v-show="visible"><span class="text-small">{{ $t('Back to top') }}</span></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style lang="scss" scoped>
div{
  display: flex;
  .btn-scroll--to_top {
    display: block;
    width: auto;
    padding: 8px 12px;
    margin: 0 auto;
    cursor: pointer;
    @include tablet-down {
      max-width: 100%;
    }
    span {
      color: white;
      &::after {
        content: url("~@/assets/img/icons/arrow-up.svg");
        padding-left: 12px;
        line-height: 0;
      }
    }
    &.no-arrow {
      span {
        &::after {
          content: unset;
        }
      }
    }
  }
}

</style>
